<template>
  <div class="wrongcontent"></div>
</template>

<script>
import { Toast } from 'vant'
import 'vant/lib/index.css'
export default {
  name: "Wrong",
  components: {
    "van-Toast": Toast,
  },
  mounted () {
    Toast('来晚了\n链接已失效')
  }

}
</script>

<style lang="less" scoped>
/deep/.van-toast {
  background-color: rgba(0, 0, 0, 0.5);
}
.wrongcontent {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(229, 251, 243, 1) 0%,
    rgba(241, 255, 239, 1) 60.72%,
    rgba(247, 247, 247, 1) 100%
  );
}
</style>